import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TooltipComponent from '../../utils/TooltipComponent';

const SplitPayableInfo = ({ product, indexKey, coupon }) => {
	return (
		<div className={`splitPaymentInfo ${indexKey === 0 ? 'primary' : ''}`}>
			<h5 className="splitPaymentTitle">
				{indexKey === 0 ? 'First' : indexKey === 1 ? 'Second' : 'Third'}{' '}
				Payment
			</h5>

			{coupon?.is_valid && (
				<span className="splitPaymentPreviousPrice">
					${(+product?.item_price)?.toFixed(2)}
				</span>
			)}

			<span className="splitPaymentPrice">
				${(+product?.price)?.toFixed(2)}
				<span style={{ fontSize: '1rem', letterSpacing: '-1px' }}>
					/month
				</span>
			</span>
		</div>
	);
};

const SplitPayment = ({ cartData, addToCart, coupon, cartLoading }) => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (cartData?.splits) {
			const isSplitSelected = cartData.splits.filter(
				each => each.price_id === cartData?.contents[0].options.price_id
			);
			isSplitSelected.length && setIsOpen(true);
		}
	}, [cartData]);

	const updateCart = useCallback(
		e => {
			let product = {
				download_id: 474227,
				price_id: e.target.value,
			};

			if (coupon?.is_valid) {
				product.discount = coupon.code;
			}

			addToCart(product, true, true);
		},
		[cartData, coupon]
	);

	const agencyLifetime = useMemo(() => {
		if (!cartData) {
			return null;
		}

		return {
			index: cartData?.contents?.findIndex(item => +item.id === 474227),
			product: cartData?.contents?.find(item => +item.id === 474227),
		};
	}, [cartData]);

	const isSplitItemSelected = useMemo(() => {
		if (!cartData) {
			return null;
		}

		return cartData?.details?.find(item => {
			return cartData?.splits?.find(splitItem => {
				return (
					+splitItem?.price_id ===
					+item?.item_number?.options?.price_id
				);
			});
		});
	}, [cartData]);

	const splitPayableViews = useMemo(() => {
		if (!isSplitItemSelected) {
			return null;
		}
		let _views = [];

		const splitedItems =
			isSplitItemSelected?.item_number?.options?.recurring?.times || 0;

		for (let index = 0; index < splitedItems; index++) {
			_views.push(
				<SplitPayableInfo
					key={index}
					indexKey={index}
					product={isSplitItemSelected}
					coupon={coupon}
				/>
			);
		}

		return _views;
	}, [isSplitItemSelected, coupon]);

	if (!cartData?.splits) {
		return null;
	}

	let classes = ['WpdLicenseSection WpdSectionGap'];

	if (cartLoading) {
		classes.push('is-loading');
	}

	if (isOpen) {
		classes.push('Expanded');
	}

	if (isSplitItemSelected) {
		classes.push('Applied');
	}

	return (
		<section className={classes.join(' ')}>
			<h4
				className="WpdLicenseSectionTitle"
				onClick={() => setIsOpen(!isOpen)}
			>
				<span>
					<span style={{ marginRight: '0.5rem' }}>
						Would you like to split your payment?
					</span>
					<TooltipComponent
						tooltipText={
							'By choosing split payments, the stated amount will be automatically charged to your card each month.'
						}
					>
						<span className="WpdLicenseSectionTitleInfo">
							<i className="wpd-icon wpd-information-solid"></i>
						</span>
					</TooltipComponent>
				</span>
				<TooltipComponent tooltipText={'Click to Enter it'}>
					<span className="WpdCollapserToggler">
						<i className="wpd-icon wpd-angle-down"></i>
					</span>
				</TooltipComponent>
			</h4>

			{isOpen && (
				<div className="WpdLicenseSectionBody">
					<div className="splitPayment">
						{cartData?.splits.map((each, index) => (
							<label
								key={index}
								className="splitPayment-item"
							>
								<input
									id={`radio-${each.price_id}`}
									type="radio"
									name="split_option"
									value={+each.price_id}
									onChange={updateCart}
									checked={
										+agencyLifetime?.product?.options
											?.price_id === +each.price_id
									}
								/>
								<span htmlFor={`radio-${each.price_id}`}>
									{each.times} Months
								</span>
							</label>
						))}
						<label
							key={3}
							className="splitPayment-item"
						>
							<input
								id={`radio-none`}
								type="radio"
								name="split_option"
								value={2}
								onChange={updateCart}
								checked={
									+agencyLifetime?.product?.options
										?.price_id === 2
								}
							/>
							<span htmlFor={`radio-none`}>None</span>
						</label>
					</div>
					{isSplitItemSelected && (
						<div className="splitPaymentInfoWrapper">
							{splitPayableViews}

							<p
								style={{
									marginTop: '10px',
									color: '#8494ba',
								}}
							>
								<strong style={{ color: 'black' }}>
									Note:
								</strong>{' '}
								Payments will be charged monthly starting from
								the date of purchase.
							</p>
						</div>
					)}
				</div>
			)}
		</section>
	);
};

export default SplitPayment;
