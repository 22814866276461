import React, { useEffect, useMemo, useState } from 'react';
import {
	lifetimeProducts,
	yearlyProducts,
} from '../../utils/AgencyBundleProducts';
import Loader from '../../utils/Loader';
import ProductIcon from '../../utils/ProductIcon';
import BetterDocsAIChatbot from './BetterDocsAIChatbot';

const CartInfo = ({ cartData, removeFromCart, cartLoading, addToCart }) => {
	const [multiItemShow, setMultiItemShow] = useState(false);
	const [bundleProducts, setBundleProducts] = useState(undefined);

	useEffect(() => {
		if (cartData && cartData.details && cartData.details.length) {
			const cartItem = cartData.details[0];
			if (parseInt(cartItem.id) === 474227) {
				setBundleProducts(
					getAgencyBundleProducts(
						parseInt(cartItem.item_number.options.price_id)
					)
				);
			} else {
				setBundleProducts(undefined);
			}
		}
	}, [cartData]);

	const getAgencyBundleProducts = priceId => {
		switch (parseInt(priceId)) {
			case 1:
				return yearlyProducts;
			case 2:
				return lifetimeProducts;
			default:
				return undefined;
		}
	};
	const getPrice = item => {
		let times = item?.item_number?.options?.recurring?.times ?? 0;

		let originalItem = { ...item };
		item = { ...originalItem };

		if (times > 0) {
			item.item_price = item.item_price * times;
			item.price = item.item_price - +originalItem.discount * times;
			item.subtotal = item.item_price;
		}

		if (item.price && item.subtotal && item.price === item.subtotal) {
			return '$' + (+item.subtotal)?.toFixed(2);
		}

		return (
			<>
				<span className="main-price">
					${(+item?.subtotal)?.toFixed(2)}
				</span>
				<span className="discounted-price">
					${(+item.price)?.toFixed(2)}
				</span>
			</>
		);
	};

	const agencyLifetime = useMemo(() => {
		if (!cartData) {
			return null;
		}

		return cartData?.contents?.find(
			item => +item.id === 474227 && +item.options.price_id > 2
		);
	}, [cartData]);

	const isSplitItemSelected = useMemo(() => {
		if (!cartData) {
			return null;
		}

		return cartData?.details?.find(item => {
			return cartData?.splits?.find(splitItem => {
				return (
					+splitItem?.price_id ===
					+item?.item_number?.options?.price_id
				);
			});
		});
	}, [cartData]);

	const cartHasBetterDocs = useMemo(() => {
		if (!cartData) {
			return false;
		}

		return (
			cartData?.details?.filter(item => +item.id === 342422)?.length > 0
		);
	}, [cartData]);

	return (
		<section className="WpdCheckoutProductSection">
			{cartLoading ? (
				<Loader component="checkout_product" />
			) : cartData && cartData.details && cartData.details.length ? (
				<div className="WpdCheckoutProductWrapper">
					{cartData.details.map((item, index) => (
						<React.Fragment key={index}>
							{((+item.id !== 1882016 && cartHasBetterDocs) ||
								!cartHasBetterDocs) && (
								<React.Fragment key={index}>
									{bundleProducts && bundleProducts ? (
										<div
											className={`WpdCheckoutProductHasMultiItem ${
												!multiItemShow
													? 'Collapsed'
													: ''
											}`}
										>
											<div className="WpdCheckoutProduct">
												<div className="WpdCheckoutProductInfo">
													<span className="WpdCheckoutProductIcon">
														<ProductIcon
															icon={
																item.item_number
																	?.image
															}
															name={item.name}
														/>
													</span>
													<span className="WpdCheckoutProductDetails">
														<h4 className="WpdCheckoutProductTitle">
															{item.name}
														</h4>
														<span className="WpdCheckoutProductSubTitle">
															{item.item_number
																.price_name && (
																<strong>
																	{
																		item
																			.item_number
																			.price_name
																	}
																</strong>
															)}
															{item.item_number
																.options
																?.recurring &&
																item.item_number
																	.options
																	.recurring
																	?.period &&
																!agencyLifetime && (
																	<span>
																		{item
																			.item_number
																			.price_name &&
																			' - '}
																		Billed
																		once per{' '}
																		{
																			item
																				.item_number
																				.options
																				.recurring
																				?.period
																		}{' '}
																		until
																		cancelled
																	</span>
																)}
														</span>
													</span>
												</div>
												<div className="WpdCheckoutProductPrice">
													{getPrice(item)}
												</div>
												<button
													className="WpdRemoveButton WpdHoverWarning"
													onClick={() =>
														removeFromCart(index)
													}
												>
													<span className="WpdRemoveIcon">
														<i className="wpd-icon wpd-cancel" />
													</span>
												</button>
											</div>
											{+item.id === 474227 && (
												<>
													<div className="WpdCheckoutSubProductList">
														{bundleProducts.map(
															(bundleItem, i) => (
																<div
																	className={
																		i >=
																		bundleProducts.length -
																			2
																			? 'WpdCol border-bottom-0'
																			: 'WpdCol'
																	}
																	key={i}
																	data-key={i}
																>
																	<div className="WpdCheckoutSubProduct">
																		<div className="WpdCheckoutProductInfo">
																			<span className="WpdCheckoutProductIcon">
																				<ProductIcon
																					icon={
																						bundleItem.icon
																					}
																					name={
																						bundleItem.name
																					}
																				/>
																			</span>
																			<span className="WpdCheckoutProductDetails">
																				<h4 className="WpdCheckoutProductTitle">
																					{
																						bundleItem.name
																					}
																				</h4>
																				<p className="WpdCheckoutProductSubTitle">
																					{
																						bundleItem.priceName
																					}
																				</p>
																			</span>
																		</div>
																	</div>
																</div>
															)
														)}
													</div>
													<div
														className="WpdCheckoutProductExpandButton"
														onClick={() =>
															setMultiItemShow(
																!multiItemShow
															)
														}
													>
														{!multiItemShow
															? 'View Bundle Products'
															: 'Hide Bundle Products'}
														<span className="WpdIcon">
															<i className="wpd-icon wpd-angle-down" />
														</span>
													</div>
												</>
											)}
										</div>
									) : (
										<div className="WpdCheckoutProduct">
											<div className="WpdCheckoutProductInfo">
												<span className="WpdCheckoutProductIcon">
													<ProductIcon
														icon={
															item.item_number
																?.image
														}
														name={item.name}
													/>
												</span>
												<span className="WpdCheckoutProductDetails">
													<h4 className="WpdCheckoutProductTitle">
														{item.name}
													</h4>
													<span className="WpdCheckoutProductSubTitle">
														{item.item_number
															.price_name && (
															<strong>
																{
																	item
																		.item_number
																		.price_name
																}
															</strong>
														)}
														{item.item_number
															.options
															?.recurring &&
															item.item_number
																.options
																.recurring
																?.period &&
															!agencyLifetime && (
																<span>
																	{item
																		.item_number
																		.price_name &&
																		' - '}
																	Billed once
																	per{' '}
																	{
																		item
																			.item_number
																			.options
																			.recurring
																			?.period
																	}{' '}
																	until
																	cancelled
																</span>
															)}
													</span>
												</span>
											</div>
											<div className="WpdCheckoutProductPrice">
												{getPrice(item)}
											</div>
											<button
												className="WpdRemoveButton WpdHoverWarning"
												onClick={() =>
													removeFromCart(index)
												}
											>
												<span className="WpdRemoveIcon">
													<i className="wpd-icon wpd-cancel" />
												</span>
											</button>
										</div>
									)}

									{+item.id === 342422 && (
										<BetterDocsAIChatbot
											id={1882016}
											addToCart={addToCart}
											cartData={cartData}
											cartLoading={cartLoading}
											removeFromCart={removeFromCart}
										/>
									)}
								</React.Fragment>
							)}
						</React.Fragment>
					))}
				</div>
			) : (
				'No items'
			)}
		</section>
	);
};

export default CartInfo;
